



























































import { AxiosPromise } from 'axios';
import { DataTableHeader } from 'vuetify';
import UserApi from '@/services/api/UserApi';
import BaseList from '@/components/base/BaseList';
import { Component } from 'vue-property-decorator';
import Actions from '@/components/renderless/Actions';

@Component({
    components: {
        Actions
    }
})
export default class UserList extends BaseList {
    public apiClient = (new UserApi());

    public headers: DataTableHeader[] = [
        { text: '№', value: 'id' },
        { text: 'First Name', value: 'first_name' },
        { text: 'Last Name', value: 'last_name' },
        { text: 'Email', value: 'email' },
        { text: 'Resume Visibility', value: 'resume_visibility' },
        { text: 'Created At', value: 'created_at' },
        { text: 'Is Admin', value: 'is_admin' },
        { text: '', value: 'actions' }
    ];

    public remove(destroy: (id: number) => AxiosPromise, id: number): void {
        destroy(id).then(() => {
            this.getItems();
        });
    }
}
