var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" Users ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"md":"2"}},[_c('lazy-search',{on:{"input":function($event){return _vm.getItems(true)}},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1)],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"items":_vm.items,"headers":_vm.headers,"options":_vm.options,"server-items-length":_vm.total,"items-per-page":_vm.itemsPerPage,"footer-props":_vm.footerProps},on:{"update:options":[function($event){_vm.options=$event},_vm.optionChanged]},scopedSlots:_vm._u([{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'resumes', query: { user_id: item.id } }}},[_vm._v(_vm._s(item.email))])]}},{key:"item.is_admin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.is_admin ? 'Yes' : 'No')+" ")]}},{key:"item.resume_visibility",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.resume_visibility ? 'Yes' : 'No')+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toDateString(item.created_at))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('actions',{attrs:{"api-client":_vm.apiClient},on:{"refresh":_vm.getItems},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var destroy = ref.destroy;
return [_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.remove(destroy, item.id)}}},[_c('v-icon',[_vm._v(" mdi-cancel ")])],1)]}}],null,true)})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }